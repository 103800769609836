<template>
  <div>
    <el-dialog
      :title="titles"
      :visible.sync="visible"
      width="40%"
      :z-index="98"
      :close-on-click-modal="false"
    >
      <ax-form ref="form" :formBuilder="formBuilder">
        <!-- <template slot="graphList" slot-scope="{ record }">
        <a-button  v-decorator="['record.graphList', { rules: [{ required: true }] }]" type="primary"  @click="openMap">
          地图标绘
        </a-button>
      </template> -->
        <template slot="graphList" slot-scope="{ record }">
          <div class="gridPoint">
            <a-button
              v-decorator="[
                'gridPoint',
                { rules: [{ required: true, message: '请地图标绘' }] },
              ]"
              type="primary"
              @click="openPoint"
            >
              地图标点
            </a-button>
            <!-- <div>
            <span>经度:</span>
            <a-input style="width: 25%" disabled placeholder="暂无数据" v-model="pointForm.Lng"></a-input>
            <span style="margin-left: 0.05rem">纬度:</span>
            <a-input style="width: 25%" disabled placeholder="暂无数据"  v-model="pointForm.Lat"></a-input>
          </div> -->
          </div>
        </template>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formSave"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="formCancel">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 地图标绘弹窗 -->
    <map-point ref="mapPoint" @retPoint="retPoint"></map-point>
  </div>
</template>

<script>
import MapPoint from "../components/mapPoint.vue";
const formList = [
  {
    label: "支部名称",
    type: "input",
    model: "name1",
    options: { maxLength: 20, placeholder: "请输入..." },
    formItem: {},
    col: { span: 12 },
    rules: [{ required: true, message: "支部名称不能为空" }],
  },
  // 级联选择器
  {
    label: "上级组织",
    type: "select",
    model: "pcode",
    options: { maxLength: 20, placeholder: "请输入..." },
    formItem: {},
    col: { span: 12 },
    rules: [{ required: true, message: "支部名称不能为空" }],
  },
  {
    label: "联系电话",
    type: "input",
    model: "phone",
    options: { maxLength: 20, placeholder: "请输入..." },
    formItem: {},
    col: { span: 12 },
    rules: [
      { required: true, message: "网格名称", placeholder: "网格(小区)名称" },
    ],
  },
  // 级联选择器
  {
    label: "地址",
    type: "treeSelect",
    model: "pcode",
    options: { disabled: false, placeholder: "请选择..." },
    formItem: {},
    col: { span: 12 },
    rules: [{ required: true, message: "上级网格" }],
  },
  {
    label: "详细地址",
    type: "",
    model: "address",
    options: {
      minRows: 4,
      maxRows: 4,
      maxLength: 200,
      placeholder: "请输入...",
    },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: false, message: "网格名称" }],
  },
  {
    label: "地图标绘",
    type: "",
    model: "graphList",
    options: {},
    formItem: {},
    col: { span: 24 },
  },
  {
    label: "楼栋纬度",
    type: "",
    model: "gridPoint1",
    options: { disabled: true, placeholder: "请地图标绘" },
    formItem: {},
    col: { span: 12 },
    rules: [{ required: true, message: "请地图标绘" }],
  },
  {
    label: "楼栋精度",
    type: "",
    model: "gridPoint2",
    options: { disabled: true, placeholder: "请地图标绘" },
    formItem: {},
    col: { span: 12 },
    rules: [{ required: true, message: "请地图标绘" }],
  },
  {
    label: "介绍",
    type: "textarea",
    model: "gridPoint3",
    options: { minRows: 4, placeholder: "请输入..." },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: false, message: "网格名称" }],
  },
];
import api from "../api";
export default {
  components: { MapPoint },
  data() {
    return {
      titles: "",
      visible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
      disabled: false,
      pcode: "",
      pointForm: {
        Lng: "",
        Lat: "",
      },
      gridPoint: "",
      saveType: false,
      mapValue: null,
      id:''
    };
  },
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.titles = record.title;
      if (record.id) {
        this.id = record.id;
        // 根据id获取详情
        this.$nextTick(async () => {
          // 表单详情
          this.getgrid(this.id);
          // 地图详情
          // const res = await api.getAtlas(record.id);
          // if (res.data === "未标绘") {
          //   this.mapValue = null;
          // } else {
          //   this.mapValue = res.data;
          // }
          // console.log(this.mapValue, "编辑");
        });
        // if (this.$refs.mapDraw) {
        //   this.$refs.mapDraw.deletes();
        // }
      }
    },
    // 获取地图经纬度信息
    retPoint(e) {
      console.log(e, "175");
      this.$nextTick(() => {
        this.$refs.form.setFieldsValue({ gridPoint1: e.Lng });
        this.$refs.form.setFieldsValue({ gridPoint2: e.Lat });
        this.$refs.form.setFieldsValue({ gridPoint: e.Lat });
      });
    },
    // 关闭弹窗
    formCancel() {
      this.mapValue = null;
      this.visible = false;
      this.$refs.form.resetFields();
      this.pointForm = {};
      this.$emit("refsh");
    },
    // 打开地图标点弹窗
    openPoint() {
      this.$refs.mapPoint.openModal();
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 获取网格点
    async getgridTree(id) {
      try {
        const res = await api.gridTree({ id: id });
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        if (!res.data.length) {
          this.$refs.form.setFormItemProp("pcode", {
            options: {
              disabled: true,
              placeholder: "上级网格",
            },
          });
        } else {
          this.$refs.form.setFormItemProp("pcode", {
            options: {
              treeData,
              allowClear: true,
              placeholder: "上级网格",
            },
            rules: [{ required: true, message: "上级网格" }],
          });
        }
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 表单提交
    async formSave() {
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        console.log(value);
        let obj = {};
        if (this.mapValue === null) {
          obj = {
            ...this.formValue,
            graphList: null,
            longitude: this.pointForm.Lng,
            latitude: this.pointForm.Lat,
          };
        } else {
          obj = {
            ...this.formValue,
            graphList: this.mapValue,
            longitude: this.pointForm.Lng,
            latitude: this.pointForm.Lat,
          };
        }
        if (this.titles === "编辑网格" && this.formId) {
          obj.id = this.formId;
          obj.isDraw = this.retIds;
        }
        this.saveType = true;
        try {
          const res = await api.addGrid(obj);
          if (res.status === 200) {
            this.$message.success("操作成功");
            this.saveType = false;
            this.$refs.addform.resetForm();
            this.visible = false;
            this.getList();
            this.pointForm = {};
          } else {
            this.$message.error("操作失败");
          }
        } catch (error) {
          console.error(error); // 处理错误
          this.saveType = false;
        }
      });
    },
    // 查询
    async getgrid(id) {
      try {
        const res = await api.getgrid(id);
        this.$refs.form.setFieldsValue(res.data);
        this.pointForm.Lng = res.data.longitude;
        this.pointForm.Lat = res.data.latitude;
        this.$nextTick(() => {
          this.$refs.form.setFieldsValue({ gridPoint1: this.pointForm.Lng });
          this.$refs.form.setFieldsValue({ gridPoint2: this.pointForm.Lat });
        });
        this.$emit("retId", res.data.isDraw);
        this.$emit("refsh");
        this.getgridTree(id);
        setTimeout(() => {
          if (!res.data.pcode) {
            this.$refs.form.setFormItemProp("pcode", {
              options: {
                disabled: true,
                placeholder: "上级网格",
              },
              rules: [{ required: false, message: "网格名称" }],
            });
          }
        }, 100);
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
  },

  created() {},

  mounted() {},
};
</script>
<style lang='less' scoped>
.gridPoint {
  display: flex;
  div {
    width: 1.5rem;
    margin-left: 20px;
    display: flex;
    align-items: center;
    span {
      margin-right: 0.05rem;
    }
  }
}
/deep/.ant-btn-primary {
  background-color: rgb(230, 162, 60) !important;
  border: 0px solid #000;
}
</style>